<template>
	<div class="btn" :class="buyInOfferSelected ? 'buyInOfferSelected' : ''" @click="selectThisBuyInOffer($event)">
		<h1>{{ buyInOffer.name }}</h1>
		<div>
			<p class="flex-column">
				<span>{{ buyInCashString() }} {{ languageStrings.cashBuys }}</span>
				<span>{{ buyInRegularCredString(buyInOffer) }} {{ languageStrings.regularCredit }}</span>
				<span>{{ languageStrings.and }} {{ buyInPromoCredString(buyInOffer) }} {{ languageStrings.promo }}</span>
			</p>
			<p>
				<small>
					<span
						>{{ languageStrings.min }}: {{ systemFormatCurrency(buyInOffer.minimumCOC, systemCurrencyTool.displayType.minorOrFull) }} | {{ languageStrings.max }}:
						{{ systemFormatCurrency(buyInOffer.maximumCOC, systemCurrencyTool.displayType.minorOrFull) }}</span
					>
				</small>
			</p>
		</div>
		<CommitBuyInOffer
			:buyInOfferSelected="buyInOfferSelected"
			:cashierState="cashierState"
			:buyInOffer="buyInOffer"
			:inGameCurrencyTool="inGameCurrencyTool"
			:systemCurrencyTool="systemCurrencyTool"
			:languageStrings="languageStrings"
			:languageErrorStrings="languageErrorStrings"
		/>
	</div>
</template>

<script>
import CommitBuyInOffer from "@/components/CommitBuyInOffer.vue";
import { onBeforeUnmount } from "vue";

export default {
	name: "BuyInOffer",
	props: {
		cashierState: Object,
		buyInOffer: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		CommitBuyInOffer,
	},
	data() {
		return {
			currencyInfo: this.cashierState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			cashOutCurrency: this.cashierState.systemSettings.cashOutCurrency,
			buyInOfferSelected: false,
		};
	},
	created() {
		this.eventBus.on("deselectBuyInOffer", (buyInId) => {
			// Deselects all buy-ins other than the id specified in the payload for this event.
			// Pass null in the payload for this event to deselect all buy-in offers.
			if (buyInId != this.buyInOffer.id) this.buyInOfferSelected = false;
		});
		onBeforeUnmount(() => {
			this.eventBus.off("deselectBuyInOffer");
		});
	},
	methods: {
		selectThisBuyInOffer(e) {
			if (e.target.id !== `reprint-button-${this.buyInOffer.id}` || e.target.className !== "strong") {
				this.eventBus.emit("deselectBuyInOffer", this.buyInOffer.id);
				this.buyInOfferSelected = true;
			}
		},
		systemFormatCurrency(valueCOC, display = {}) {
			return this.systemCurrencyTool.formatCurrency(valueCOC, display);
		},
		buyInCashString() {
			const compareFactor = this.systemCurrencyTool.currencyInfo.minorDigits < 1 ? 100 : 1;
			return this.systemCurrencyTool.formatCurrency(compareFactor, this.systemCurrencyTool.displayType.minorWholeOrFull);
		},
		buyInRegularCredString(buyInOffer) {
			const compareFactor = this.systemCurrencyTool.currencyInfo.minorDigits < 1 ? 100 : 1;
			return this.inGameCurrencyTool.formatFromAU(
				compareFactor * buyInOffer.creditRegularAUPerCashCOC,
				this.inGameCurrencyTool.displayType.minorWholeOrFull
			);
		},
		buyInPromoCredString(buyInOffer) {
			const compareFactor = this.systemCurrencyTool.currencyInfo.minorDigits < 1 ? 100 : 1;
			return this.inGameCurrencyTool.formatFromAU(
				compareFactor * buyInOffer.creditPromoAUPerCashCOC,
				this.inGameCurrencyTool.displayType.minorWholeOrFull
			);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
small {
	display: block;
	text-align: center;
}

h1 {
	line-height: 1.125em;
}

.flex-column {
	display: flex;
	flex-flow: column nowrap;
}

p.flex-column {
	text-align: center;
}

.btn:hover {
	transform: none;
}

.buyInOfferSelected.card {
	background-color: green;
	color: #fff;
}
</style>
