<template>
	<div>
		<div id="currentBank">
			<table>
				<tr class="header-row">
					<th>
						<button @click="showCashierBankSummary()" class="btn">{{ languageStrings.cashierSummary }}</button>
					</th>
					<th>
						<button @click="showCloseDialog(true)" class="btn">{{ languageStrings.closeThisBank }}</button>
					</th>
				</tr>
				<tr class="header-row">
					<th colspan="2">{{ languageStrings.bankText }} #{{ cashierState?.currentBank?.id }} {{ languageStrings.balanceText }}</th>
				</tr>
				<tr class="data-row">
					<th>Cash</th>
					<td>{{ systemFormatCurrency(cashierState?.currentBank?.currentBalanceCOC, systemCurrencyTool.displayType.minorOrFull) }}</td>
				</tr>
			</table>

			<table id="player-funds">
				<tr
					class="header-row"
					v-if="
						cashierState?.playerAccount?.accessCodeConfirmed &&
							cashierState?.playerAccount?.user?.id &&
							cashierState?.playerAccount?.account?.fundsLocks.length > 0
					"
				>
					<th style="width: 50%">
						<button @click="requestClosePlayerAccount(cashierState?.playerAccount)" class="btn">{{ languageStrings.endPlayerSession }}</button>
					</th>
					<th>
						<button @click="requestFundsLockManager(cashierState?.playerAccount)" class="btn">{{ languageStrings.listLockedFunds }}</button>
					</th>
				</tr>
				<tr class="header-row" v-else-if="cashierState?.playerAccount?.user?.id">
					<th colspan="2">
						<button @click="requestClosePlayerAccount(cashierState?.playerAccount)" class="btn">{{ languageStrings.endPlayerSession }}</button>
					</th>
				</tr>
				<tr class="header-row" v-if="cashierState?.playerAccount?.user?.id">
					<th colspan="2">{{ cashierState?.playerAccount?.user?.displayName }} {{ languageStrings.balanceText }}</th>
				</tr>
				<tr class="header-row" v-else>
					<th colspan="2" class="no-player-account">{{ languageStrings.noPlayerAccountOpen }}</th>
				</tr>
				<tr class="data-row" v-if="cashierState?.playerAccount?.user?.id">
					<th>{{ languageStrings.cashText }}</th>
					<td>
						{{
							inGameformatFromAU(
								cashierState?.playerAccount?.account?.balance?.regularAU,
								inGameCurrencyTool.displayType.minorWholeOrFull
							)
						}}
						/
						{{
							systemFormatFromAU(
								cashierState?.playerAccount?.account?.balance?.regularAU,
								systemCurrencyTool.displayType.minorWholeOrFull
							)
						}}
					</td>
				</tr>
				<tr class="data-row" v-if="cashierState?.playerAccount?.user?.id">
					<th>{{ languageStrings.promoText }}</th>
					<td>
						{{
							inGameformatFromAU(
								cashierState?.playerAccount?.account?.balance?.promoAU,
								inGameCurrencyTool.displayType.minorWholeOrFull
							)
						}}
						/
						{{
							systemFormatFromAU(
								cashierState?.playerAccount?.account?.balance?.promoAU,
								systemCurrencyTool.displayType.minorWholeOrFull
							)
						}}
					</td>
				</tr>
			</table>
		</div>
		<div class="btn-set">
			<span
				v-if="!cashierState?.playerAccount?.accountAccessCode && !pagePlayerIdentityAuthentication"
				id="OpenPlayerAccount"
				:class="currentComponent == 'OpenPlayerAccount' ? 'active' : ''"
				class="btn inline"
				@click="currentComponent = 'PlayerIdentityAuthentication'"
			>
				{{ openPlayerButton }}
			</span>
			<span
				v-if="cashierState?.playerAccount?.accountAccessCode"
				id="BuyIns"
				:class="currentComponent == 'BuyIns' && cashierState.transferFundsIn ? 'active' : ''"
				class="component-select btn inline"
				>{{ languageStrings.buyInText }}</span
			>
			<span
				v-if="cashierState?.playerAccount?.accountAccessCode"
				id="FundsTransferOut"
				:class="currentComponent == 'FundsTransfer' && !cashierState.transferFundsIn ? 'active' : ''"
				class="component-select btn inline"
				>{{ languageStrings.cashOutText }}</span
			>
			<span id="FillCashierBank" :class="currentComponent == 'FillCashierBank' ? 'active' : ''" class="component-select btn inline"
				>{{ languageStrings.fillCashierBank }}</span
			>
			<span
				id="ListTransfersfromCashierBank"
				:class="currentComponent == 'ListTransfersfromCashierBank' ? 'active' : ''"
				class="component-select btn inline"
				>{{ languageStrings.bankTransfers }}</span
			>
			<span id="ListCashierBankFills" :class="currentComponent == 'ListCashierBankFills' ? 'active' : ''" class="component-select btn inline"
				>{{ languageStrings.bankFills }}</span
			>
		</div>
		<dialog id="close-dialog">
			<div v-if="this.openBankMessage">
				<p id="text-container">
					{{ `${this.languageStrings.closeBankMessage}, ${this.openBankMessage}` }}
				</p>
				<div id="btn-container">
					<button id="confirmButton" class="btn" value="default" @click="closeCashierBank(cashierState?.currentBank?.id)">Ok</button>
					<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="closeModal()">Cancel</button>
				</div>
			</div>
			<div v-if="!this.openBankMessage">
				<p id="text-container">
					{{ `Are you sure you want to end this session with ${account?.user.displayName ? account?.user.displayName : account?.user.playerPhone}` }}
				</p>
				<div id="btn-container">
					<button id="confirmButton" class="btn" value="default" @click="closePlayerAccount()">{{ languageStrings.endPlayerSession }}</button>
					<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="closeModal()">Cancel</button>
				</div>
			</div>
		</dialog>
		<component
			:is="currentComponent"
			:casinoList="casinoList"
			:cashierState="cashierState"
			:appDataBus="appDataBus"
			:portExists="portExists"
			:forceChildComponent="forceChildComponent"
			:cashierBankId="currentBankId"
			:inGameCurrencyTool="inGameCurrencyTool"
			:systemCurrencyTool="systemCurrencyTool"
			:isMobile="isMobile"
			:languageStrings="languageStrings"
			:languageErrorStrings="languageErrorStrings"
		/>
	</div>
</template>

<script>
// @ is an alias to /src
import OpenPlayerAccount from "@/components/OpenPlayerAccount";
import GetAccountAccessCode from "@/components/GetAccountAccessCode";
import BuyIns from "@/components/BuyIns.vue";
import FundsTransfer from "@/components/FundsTransfer.vue";
import FillCashierBank from "@/components/FillCashierBank.vue";
import ListTransfersfromCashierBank from "@/components/ListTransfersfromCashierBank.vue";
import ListCashierBankFills from "@/components/ListCashierBankFills.vue";
import CashierBankSummary from "@/components/CashierBankSummary";
import NumInputPad from "@/components/NumInputPad.vue";
import PlayerIdentityAuthentication from "@/components/PlayerIdentityAuthentication";
import router from "@/router";
import { reactive, onBeforeUnmount } from "vue";

export default {
	name: "BankView",
	props: {
		cashierState: {
			type: Object,
		},
		appDataBus: {
			type: Object,
		},
		forceChildComponent: {
			type: String,
		},
		casinoList: {
			type: Array,
		},
		isMobile: Boolean,
		portExists: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		OpenPlayerAccount,
		GetAccountAccessCode,
		BuyIns,
		FundsTransfer,
		FillCashierBank,
		ListTransfersfromCashierBank,
		ListCashierBankFills,
		CashierBankSummary,
		NumInputPad,
		PlayerIdentityAuthentication,
	},
	data() {
		return {
			openPlayerButton: "Begin Player Session",
			activeSession: this.session.get(),
			currentBankId: "",
			currentBank: {},
			currentBalance: {},
			regularAU: 0,
			promoAU: 0,
			hasFundsLocks: false,
			playerCurrentBalance: {},
			playerRegularAU: 0,
			playerPromoAU: 0,
			playerDisplayName: "",
			playerUserId: "",
			status: Object.assign({}, this.globalStatus),
			currentComponent: "PlayerIdentityAuthentication",
			currentComponentId: null,
			setcurrentCompnentWrapper: null,
			currencyInfo: this.cashierState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			pagePlayerIdentityAuthentication: true,
			inputDialog: null,
			openBankMessage: "",
		};
	},
	watch: {
		currentComponent() {
			this.setButtonsActiveState();
		},
		forceChildComponent() {
			this.currentComponentId = null;
			if (this.forceChildComponent === "FundsTransfer") {
				this.currentComponentId = this.transferFundsIn ? "FundsTransferIn" : "FundsTransferOut";
			}
			this.currentComponent = this.forceChildComponent;
			this.setButtonsActiveState();
		},
		cashierState: {
			handler() {
				this.setButtonsActiveState();
			},
			deep: true,
		},
		transferFundsIn() {
			this.setButtonsActiveState();
		},
	},
	updated() {
		this.$nextTick(() => {
			// Code runs only after the view has been re-rendered
			this.resetButtonEvents();
			this.setButtonsActiveState();
		});
	},
	created() {
		this.eventBus.on("setBuyinComponent", () => {
			this.currentComponent = "BuyIns";
		});
		this.eventBus.on("closeCashierBankSummaryModal", () => {
			// this.clearPlayerData();
			this.currentComponent = "ListCashierBankFills";
		});
		this.eventBus.on("setOpenPlayerCompoment", () => {
			this.currentComponent = "GetAccountAccessCode";
		});
		this.eventBus.on("retrySMS", () => {
			this.currentComponent = "GetAccountAccessCode";
		});
		this.eventBus.on("getAccountAccessCodeSuccess", (override = false) => {
			if (override) {
				let status = Object.assign({}, this.status);
				status.ok = true;
				status.message = "Code from app override";
				this.eventBus.emit("updateStatus", status);
			}
			this.currentComponent = "OpenPlayerAccount";
		});
		onBeforeUnmount(() => {
			this.eventBus.off("setOpenPlayerCompoment");
			this.eventBus.off("retrySMS");
			this.eventBus.off("getAccountAccessCodeSuccess");
			this.eventBus.off("closeCashierBankSummaryModal");
			const componentSelectButtons = document.querySelectorAll(".component-select");
			componentSelectButtons.forEach((btn) => {
				btn.removeEventListener("click", this.setcurrentCompnentWrapper);
			});
		});
	},
	async mounted() {
		this.currentComponent = !this.cashierState?.playerAccount?.accessCodeConfirmed ? "PlayerIdentityAuthentication" : "BuyIns";
		let STATE = this.cashierState;
		let thisInstance = this;
		this.currentBankId = STATE?.currentBank?.id || this.activeSession.cashierBankId;
		if (!this.currentBankId) router.push("/");
		let regAU = STATE?.currentBank?.currentBalance?.regularAU || this.activeSession?.currentBank?.currentBalance?.regularAU;
		let proAU = STATE?.currentBank?.currentBalance?.promoAU || this.activeSession?.currentBank?.currentBalance?.promoAU;
		this.regularAU = regAU;
		this.promoAU = proAU;
		this.inputDialog = document.getElementById("close-dialog");

		const componentSelectButtons = document.querySelectorAll(".component-select");
		this.setcurrentCompnentWrapper = function() {
			thisInstance.setCurrentComponent(this);
		};
		componentSelectButtons.forEach((btn) => {
			btn.addEventListener("click", thisInstance.setcurrentCompnentWrapper);
		});
		this.setButtonsActiveState();
	},
	methods: {
		formatCurrency(valueCOC, display = {}) {
			return this.inGameCurrencyTool.formatCurrency(valueCOC, display);
		},
		systemFormatCurrency(valueCOC, display = {}) {
			return this.systemCurrencyTool.formatCurrency(valueCOC, display);
		},
		inGameformatFromAU(valueAU, display = {}) {
			return this.inGameCurrencyTool.formatFromAU(valueAU, display);
		},
		systemFormatFromAU(valueAU, display = {}) {
			return this.systemCurrencyTool.formatFromAU(valueAU, display);
		},
		showCashierBankSummary() {
			this.currentComponent = "CashierBankSummary";
		},
		resetButtonEvents() {
			let thisInstance = this;
			let componentSelectButtons = document.querySelectorAll(".component-select");
			componentSelectButtons.forEach((btn) => {
				btn.removeEventListener("click", thisInstance.setcurrentCompnentWrapper);
			});
			componentSelectButtons.forEach((btn) => {
				btn.addEventListener("click", thisInstance.setcurrentCompnentWrapper);
			});
		},
		setButtonsActiveState() {
			let openPlayerButtonElement = document.getElementById("OpenPlayerAccount");
			if (this.cashierState?.playerAccount?.accountAccessCode && openPlayerButtonElement && !this.pagePlayerIdentityAuthentication) {
				openPlayerButtonElement.classList.add("player-account-open");
				this.openPlayerButton = "End Player Session";
			}

			if (!this.cashierState?.playerAccount?.accountAccessCode && openPlayerButtonElement && !this.pagePlayerIdentityAuthentication) {
				openPlayerButtonElement.classList.remove("player-account-open");
				this.openPlayerButton = "Begin Player Session";
			}

			this.setPagePlayerIdentityAuthentication();
		},
		setPagePlayerIdentityAuthentication() {
			this.pagePlayerIdentityAuthentication = this.currentComponent === "PlayerIdentityAuthentication" ? true : false;
		},
		setCurrentComponent(btnElem) {
			let STATE = this.cashierState;
			let btnTarget = btnElem.id;
			this.currentComponentId = null;

			switch (btnTarget) {
				case "OpenPlayerAccount":
					if (STATE?.playerAccount?.user?.id) {
						this.requestClosePlayerAccount(STATE.playerAccount);
					} else {
						this.currentComponent = "GetAccountAccessCode";
					}
					this.setPagePlayerIdentityAuthentication();
					break;
				case "BuyIns":
					this.currentComponent = "BuyIns";
					STATE.currentComponentId = "BuyIns";
					STATE.transferFundsIn = true;
					this.eventBus.emit("updateCashierState", STATE);
					this.eventBus.emit("transferFundsConfig", STATE.currentComponentId);
					this.setButtonsActiveState();
					return;
				case "FundsTransferOut":
					this.currentComponent = "FundsTransfer";
					STATE.currentComponentId = "FundsTransferOut";
					STATE.transferFundsIn = false;
					this.eventBus.emit("updateCashierState", STATE);
					this.eventBus.emit("transferFundsConfig", STATE.currentComponentId);
					this.setButtonsActiveState();
					return;
				case "FillCashierBank":
					this.currentComponent = "FillCashierBank";
					this.setButtonsActiveState();
					break;
				case "ListTransfersfromCashierBank":
					this.currentComponent = "ListTransfersfromCashierBank";
					this.setButtonsActiveState();
					break;
				case "ListCashierBankFills":
					this.currentComponent = "ListCashierBankFills";
					this.setButtonsActiveState();
					break;
				default:
				// this.currentComponent = this.forceChildComponent || "OpenPlayerAccount";
			}

			STATE.currentComponentId = null;
			this.eventBus.emit("updateCashierState", STATE);
		},
		requestFundsLockManager() {
			router.push("/fundsLockManager");
		},
		clearPlayerData() {
			let STATE = this.cashierState;
			STATE.playerAccount = {};
			this.playerDisplayName = "";
			this.playerUserId = "";
			this.playerRegularAU = 0;
			this.playerPromoAU = 0;
			this.eventBus.emit("updateCashierState", STATE);
		},
		requestClosePlayerAccount(account = null) {
			// check if account - closeCashierBank() needs to clear player account without close player confirm dialog
			if (account) {
				this.showCloseDialog(false);
			}
		},

		closePlayerAccount(){
			this.clearPlayerData();
			this.currentComponent = "PlayerIdentityAuthentication";
			this.closeModal();
		},

		async closeCashierBank(cashierBankId = null) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				this.closeModal();
				return false;
			}

			try {
				let bankId = cashierBankId || this.cashierState.currentBank.id;

				var bankClosed = await this.closeBank(this, bankId);

				let bankSummary = await this.getCashierBankSummary(this, cashierBankId);

				this.eventBus.emit("printTicket", this.prepareClosingReceipt(this.cashierState, bankSummary, this.systemCurrencyTool));

				if (bankClosed?.ok) {
					this.requestClosePlayerAccount();
					// this.eventBus.emit("printTicket", this.prepareClosingReceipt(this.cashierState, bankSummary, this.inGameCurrencyTool));
					router.push("/");
				}
				this.eventBus.emit("updateStatus", this.globalStatus);
				this.closeModal();
			} catch (e) {
				console.error(e);
				this.closeModal();
			}
		},

		showCloseDialog(closeBank = null) {
			if(closeBank) {
				this.openBankMessage = this.openBankMessage = this.cashierBankId ? `bank #${this.cashierBankId}` : "your bank";
			}else {
				this.openBankMessage = false;
			}
			this.inputDialog.showModal();
		},

		closeModal() {
			this.inputDialog.close();
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#currentBank {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	background-color: #32373f;
	color: #000;
}
#currentBank h2 {
	width: 100%;
	text-transform: uppercase;
}
#currentBank .header-row th {
	width: 50%;
	text-transform: uppercase;
}
#currentBank table {
	margin: 0;
	background: #a6b0b9;
	color: #000;
	border: 2px #fff solid;
	flex-basis: 33%;
}
#currentBank th {
	margin: 0;
	padding: 5px;
}
#currentBank td:last-child {
	text-align: right;
	padding: 0 10px;
	font-weight: 700;
}
#currentBank table button.btn {
	font-size: 0.75em;
	margin: 0 auto;
	padding: 2px 5px;
	text-align: center;
	min-width: 6em;
}
#currentBank .header-row {
	position: relative;
}
.btn-set {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}
.btn.inline {
	display: inline-block;
}
.no-player-account {
	background-color: #000;
	color: #fff;
}
.player-account-open {
	background-color: #000;
}
.player-account-open:hover {
	background-color: #4971a4;
}
#close-dialog {
	margin: auto;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}
#btn-container {
	display: flex;
	margin-left: 1.5vw;
}

#text-container {
	text-align: center;
}

@media (min-width: 768px) {
	/* #currentBank table button.btn {
		white-space: nowrap;
	} */
}

@media (max-width: 768PX) {
	#btn-container {
		margin-left: 7.5vw;
	}
}
</style>
