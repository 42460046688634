<template>
	<div id="fundsTransfer" class="input-heading">
		<h3>Buy-in Offers</h3>
		<div class="buy-in-offer card-container" :class="isMobile ? 'is-mobile' : ''">
			<BuyInOffer
				class="card"
				v-for="(buyInOffer, index) in buyInOffers"
				:id="`offer-${buyInOffer.id}`"
				:key="index"
				:value="buyInOffer.value"
				:buyInOffer="buyInOffer"
				:cashierState="cashierState"
				:inGameCurrencyTool="inGameCurrencyTool"
				:systemCurrencyTool="systemCurrencyTool"
				:languageStrings="languageStrings"
				:languageErrorStrings="languageErrorStrings"
			/>
		</div>
	</div>
</template>

<script>
import BuyInOffer from "@/components/BuyInOffer.vue";
import { onBeforeUnmount } from "vue";
import OpenCashierBankVue from "../views/OpenCashierBank.vue";

export default {
	name: "BuyIns",
	inheritAttrs: false,
	props: {
		cashierState: {
			type: Object,
		},
		appDataBus: {
			type: Object,
		},
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		BuyInOffer,
	},
	data() {
		return {
			buyInOffers: this.cashierState.playerAccount.buyInOffers.sort((a, b) => b.priority - a.priority),
			currencyInfo: this.cashierState.currencyInfo,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.buy-in-offer {
	display: flex;
	flex-flow: row wrap;
}

.input-section div {
	flex-direction: column;
}

#fundsTransfer {
	padding-bottom: 15px;
	height: calc(100vh - 22em);
	overflow: hidden auto;
}

.fundsTransfer {
	width: 100%;
	margin: 30px auto;
}

.fundsTransfer tr {
	cursor: pointer;
}

#fundsTransfer::-webkit-scrollbar-track {
	box-shadow: inset 1px 1px 6px rgb(0 0 0 / 75%);
	border-radius: 16px;
	background-color: #434250;
}

#fundsTransfer::-webkit-scrollbar {
	width: 32px;
}

#fundsTransfer::-webkit-scrollbar-thumb {
	border-radius: 16px;
	box-shadow: inset -2px -2px 6px rgb(0 0 0 / 75%);
	background-color: #bccfe5;
}

#fundsTransfer .input-section {
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
	box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%), inset -2px -2px 10px 2px rgb(0 0 0 / 30%);
}

.card-container {
	display: block;
	/* padding: 15px; */
}

.card {
	position: relative;
	/* margin: 15px auto; */
	padding: 15px;
	background-color: #bccfe5;
	color: #000;
	font-weight: bold;
	border-radius: 0.5em;
	box-shadow: 2px 3px 5px 0px hsl(0deg 0% 0% / 70%);
	user-select: none;
	min-width: calc(100% - 30px);
}

.transfer,
.reprint {
	margin: 5px auto;
}

.card-container.is-mobile {
	margin-bottom: 15px;
	padding-bottom: 100px;
}

@media (min-width: 768px) {
	.card-container {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
		column-gap: 2%;
	}

	.card-container.two-col {
		grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
	}

	.card-container.three-col {
		grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
	}

	.two-col .card {
		width: 95%;
	}
}

@media (min-width: 1024px) {
	.card-container {
		grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
	}
}
</style>
