<template>
	<div id="fillCashierBank" class="input-heading">
		<h3>{{ languageStrings.fillBank }}</h3>
		<div class="input-section">
			<div>
				<label for="currentBalanceCOC">{{ languageStrings.cashText }}</label>
				<input
					type="hidden"
					name="fillAmountAU"
					@click="numInputPadInit('fillAmountAU')"
					v-model="fillAmountAU"
					placeholder="fill Amount"
					autocomplete="off"
					onfocus="blur();"
				/>
				<input
					class="max-fifty"
					type="text"
					name="currentBalancString"
					@click="numInputPadInit('fillAmountAU')"
					v-model="fillAmountString"
					placeholder="Fill Amount"
					autocomplete="off"
					onfocus="blur();"
				/>
			</div>
			<button class="fill-bank btn" type="button" @click="fillCashierBank()">{{ languageStrings.fillBank }}</button>
			<table v-if="showTransaction" class="currentBalanceCOC">
				<tr class="header-row">
					<th>{{ languageStrings.transactionComplete }}</th>
					<th>{{ languageStrings.currentBankFunds }}</th>
				</tr>
				<tr class="data-row">
					<th>Cash</th>
					<td>
						{{
							systemCurrencyTool.formatCurrency(
								cashierState?.currentBank?.currentBalanceCOC,
								systemCurrencyTool.displayType.minorWholeOrFull
							)
						}}
					</td>
				</tr>
			</table>
		</div>
	</div>
	<transition name="fade">
		<NumInputPad
			v-if="inputConfig.inputField"
			:inputConfig="inputConfig"
			:currencyInfo="cashierState.currencyInfo"
			:inGameCurrencyTool="inGameCurrencyTool"
			:systemCurrencyTool="systemCurrencyTool"
			:languageStrings="languageStrings"
			:languageErrorStrings="languageErrorStrings"
		/>
	</transition>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import NumInputPad from "@/components/NumInputPad.vue";
import { onBeforeUnmount } from "vue";

export default {
	name: "FillCashierBank",
	inheritAttrs: false,
	props: {
		cashierState: {
			type: Object,
		},
		appDataBus: {
			type: Object,
		},
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	components: {
		NumInputPad,
	},
	data() {
		return {
			activeSession: this.session.get(),
			status: Object.assign({}, this.globalStatus),
			inputConfig: Object.assign({}, this.globalInputConfig),
			cashierBankId: "",
			currentBalanceCOC: "",
			currencyInfo: this.cashierState.currencyInfo,
			showTransaction: false,
			fillAmountAU: 0,
			fillAmountString: "0",
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			systemMinorOrFull: this.systemCurrencyTool.displayType.minorOrFull,
		};
	},
	watch: {
		fillAmountAU() {
			this.fillAmountString = this.systemCurrencyTool.formatFromAU(this.fillAmountAU, this.systemMinorOrFull);
		},
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("closeKeypad", () => {
			thisInstance.inputConfig.inputField = null;
		});
		onBeforeUnmount(() => {
			thisInstance.inputConfig.inputField = null;
			thisInstance.eventBus.off("closeKeypad");
			this.eventBus.off("closeKeypad");
		});
	},
	methods: {
		numInputPadInit(inputField) {
			this.inputConfig.inputField = inputField;
			this.inputConfig.keypadInput = this.currentBalanceCOC;
			this.inputConfig.locale = true;
		},
		async fillCashierBank() {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			try {
				this.showTransaction = false;
				this.cashierBankId = this.cashierState?.currentBank?.id ? this.cashierState.currentBank.id : false;

				if (!this.cashierBankId) {
					let bankList = await sharedScripts.getBanksList(this, true, languageErrorStrings);

					let bankListStatus = sharedScripts.checkFetchErrors(bankList, this.languageErrorStrings);

					if (bankListStatus && !bankListStatus.ok) {
						this.eventBus.emit("updateStatus", bankListStatus);
						return;
					}
					let openBank = bankList.find((element) => element.cashierUserId == this.activeSession.userId);
					this.cashierBankId = openBank.id;
				}

				let currentBalanceCOC = this.systemCurrencyTool.toCurrencyFromAU(this.fillAmountAU);

				switch (true) {
					case !sharedScripts.isValidTransferValue(this.fillAmountAU):
						this.status.message = "Regular amount must be greater than -1,000,000,000 and less than 1,000,000,000";
						this.status.ok = false;
						this.eventBus.emit("updateStatus", this.status);
						return;
					case !currentBalanceCOC:
						this.status.message = "You must enter some amount to fill into bank";
						this.status.ok = false;
						this.eventBus.emit("updateStatus", this.status);
						return;
				}

				let body = {
					CashierBankId: this.cashierBankId,
					ToBankCashCOC: currentBalanceCOC,
				};

				let requestUrl = new URL("/api/v1/cashier/bank/fill", this.rabbitsfootHostUrl);
				let headerObj = new Headers();
				headerObj.append("Authorization", `Bearer ${this.activeSession.accessToken}`);
				headerObj.append("Content-Type", "application/json; charset=utf-8");
				let request = new Request(requestUrl.toString(), {
					method: "POST",
					body: JSON.stringify(body),
					headers: headerObj,
				});

				this.serverStatus.serverBusy = true;

				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return false;
				}

				var dataObj = await response.json();
				this.cashierBankId = dataObj?.cashierBank?.id;

				this.currentBalanceCOC = 0;
				this.fillAmountAU = 0;
				this.showTransaction = true;

				let STATE = this.cashierState;
				STATE.currentBank.currentBalanceCOC = dataObj.cashierBank.currentBalanceCOC;
				this.eventBus.emit("updateCashierState", STATE);
				this.status.message = `Bank ${this.cashierState.cashierBankId} filled successfully`;
				this.status.ok = true;
				this.eventBus.emit("updateStatus", this.status);
				this.serverStatus.serverBusy = false;
			} catch (e) {
				console.error(e);
				this.serverStatus.serverBusy = false;
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#fillCashierBank {
	padding-bottom: 30px;
	height: calc(100vh - 21em);
	overflow: hidden auto;
}

#fillCashierBank::-webkit-scrollbar-track {
	box-shadow: inset 1px 1px 6px rgb(0 0 0 / 75%);
	border-radius: 16px;
	background-color: #434250;
}

#fillCashierBank::-webkit-scrollbar {
	width: 32px;
}

#fillCashierBank::-webkit-scrollbar-thumb {
	border-radius: 16px;
	box-shadow: inset -2px -2px 6px rgb(0 0 0 / 75%);
	background-color: #bccfe5;
}

#fillCashierBank .input-section {
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
	box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%), inset -2px -2px 10px 2px rgb(0 0 0 / 30%);
}

.fill-bank {
	margin: 5px auto;
}
</style>
