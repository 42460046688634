<template>
	<div id="identity-container">
		<h2>Begin Player Session</h2>
		<button v-if="mediaSupport" class="btn" @click="showScanner = true" title="Use QR Code Scanner">Use QR Code Scanner</button>
		<button class="btn" @click="setOpenPlayerCompoment()" title="Use SMS Fallback">Use SMS Fallback</button>
		<div class="label-input">
			<label for="verbal-code" title="Verbal Code Manual Entry">Verbal Code Manual Entry</label>
			<div class="manual-entry">
				<input
					autofocus
					class="monospace"
					id="verbal-code"
					type="text"
					name="verbal-code"
					placeholder="Verbal Code"
					title="Verbal Code Manual Entry"
					v-model="verbalCode"
					@keyup.enter="getPlayerAccount()"
				/>
				<button class="btn" @click="getPlayerAccount()">Submit</button>
			</div>
		</div>
		<div id="scanner-container" v-if="showScanner">
			<StreamBarcodeReader @decode="onDecode" @loaded="onLoaded" />
			<button class="btn" @click="showScanner = false">Close Scanner</button>
		</div>
		<h1 v-if="verbalCode && devMode">
			Code is: <span class="monospace">{{ verbalCode }}</span>
		</h1>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
	name: "PlayerIdentityAuthentication",
	components: {
		StreamBarcodeReader,
	},
	props: {
		cashierState: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			showScanner: false,
			verbalCode: null,
			playerAccount: {},
			serverBusy: false,
			busyText: "",
			mediaSupport: "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices,
		};
	},
	methods: {
		setOpenPlayerCompoment() {
			this.eventBus.emit("setOpenPlayerCompoment");
		},
		startScanning() {
			this.timer = 0;
			this.showScanner = true;
			this.verbalCode = "";
			this.timerInterval = setInterval(() => {
				this.timer += 0.1;
				this.timer = Number(this.timer.toFixed(1));
			}, 100);
		},
		onDecode(text) {
			this.showScanner = false;
			this.verbalCode = text.split("-")[0] === "MGSO" ? text.split("-")[1] : "";
			clearInterval(this.timerInterval);
			if (this.devMode) console.log(`Decode text from QR code is ${this.verbalCode}`);
			this.getPlayerAccount();
		},
		onLoaded() {
			if (this.devMode) console.log(`Ready to start scanning QR codes`);
		},
		async getPlayerAccount() {
			this.serverBusy = true;
			this.busyText = "Validating code";

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			if (!this.verbalCode) {
				this.status.ok = false;
				this.status.message = "No verbal code input.";
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.cashierState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/cashier/code/lookup", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.cashierState.casinoId && !this.cashierState.systemSettings.features.allowMultiCasinoAccountAccessCodes)
				params.set("casinoId", this.cashierState.casinoId);
			if (this.verbalCode && !this.cashierState.systemSettings.features.allowMultiCasinoAccountAccessCodes)
				params.set("verbalCode", this.verbalCode);

			requestUrl.search = params.toString();

			let requestObject = {
				method: this.cashierState.systemSettings.features.allowMultiCasinoAccountAccessCodes ? "POST" : "GET",
				headers: headerObj,
			};

			if (this.cashierState.systemSettings.features.allowMultiCasinoAccountAccessCodes) {
				let body = {
					CasinoId: this.cashierState.casinoId,
					VerbalCode: this.verbalCode,
				};
				requestObject.body = JSON.stringify(body);
			}

			let request = new Request(requestUrl.toString(), requestObject);

			console.log(request);

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.playerAccount = dataJson;

				this.showScanner = false;

				let _state = this.cashierState;
				_state.playerAccount = this.playerAccount;
				_state.playerAccount.accountAccessCode = this.playerAccount.code;
				_state.playerAccount.accessCodeConfirmed = true;
				_state.transferFundsIn = true;
				_state.forceChildComponent = "BuyIns";
				_state.currentComponentId = "FundsTransferIn";

				console.log(_state);

				this.eventBus.emit("updateCashierState", _state);
				this.eventBus.emit("setBuyinComponent");

				this.status.message = `${_state.playerAccount.user.displayName} Account Open Success`;
				this.status.ok = true;
				this.eventBus.emit("updateStatus", this.status);

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
	},
};
</script>

<style scoped>
#identity-container {
	display: flex;
	height: 60%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#identity-container .label-input {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: hsl(0deg 0% 36%);
	padding: 5px 10px 5px 5px;
	border-radius: 8px;
	margin-top: 15px;
}

.monospace {
	font-family: monospace;
	font-size: 1.25em;
	width: 6.5em;
	text-transform: uppercase;
}

#scanner-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: grid;
	align-items: center;
	background-color: rgb(0 0 0 / 90%);
	justify-items: center;
}

#scanner-container .overlay-element {
	background: unset;
}

.manual-entry {
	display: flex;
}

.manual-entry button {
	min-width: unset;
	padding: 5px;
	margin: auto;
}
</style>
